import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ['locationParam']

  connect() {
    const locationParam = this.locationParamTarget.getAttribute("location");

    if (locationParam && locationParam.trim() !== '') {
      const element = document.querySelector(`.accordion-collapse.collapse[location='${locationParam}']`);

      if (element) {
        element.classList.add("show");

        $('html, body').animate({
          scrollTop: $(element).offset().top
        }, 1000); // Duration of the animation in milliseconds
      };
    }
  }
}
