import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {

  static targets = ['userUnpairedDevices', 'devicesSelect', 'userSelect', 'clientContact', 'readingUnit', 'readingUnitNotice', 'tokenId', 'manufacturerSelect', 'manufacturerText', 'meterTypeSelect', 'meterTypeText', 'meterFlowSelect', 'meterFlowText', 'newDeviceNumber', 'newDeviceNumberNotice', 'nomUserId', 'readingUnitGroup', 'oldPhotoInput', 'oldPhotoPreview', 'oldPhotoData', 'newPhotoInput', 'newPhotoPreview', 'newPhotoData', 'rtxGroups', 'wmbusCheckbox', 'protocolId', 'oldPhotoLoading', 'newPhotoLoading', 'tookOverName', 'tookOverEmail', 'tookOverNotPresentButton']

  initialize() {
    if (this.hasWmbusCheckboxTarget) {
      this.toggleRtxGroups();
      this.select2init();
    }

    this.initializeFileInputs();
  }

  reloadDevices() {
    const controller = this;

    $.ajax({
      // url: `/assembly_protocols/user_unpaired_devices.json`,
      url: `/assembly_protocols/user_unpaired_devices`,
      type: 'get',
      data: `nom_user_id=${this.userSelectTarget.value}`,
      success: function (response) {
        document.querySelector('#devices-accordion').innerHTML = response;
      }
    })
  }

  setUserDevices(data) {
    this.userUnpairedDevices(data)
    this.refreshDevicesSelectValues(data)
  }

  userUnpairedDevices(data) {
    this.userUnpairedDevicesTarget.innerHTML = ''

    data.forEach((device) => {
      const span = document.createElement("span");
      span.innerHTML = device.name;
      this.userUnpairedDevicesTarget.appendChild(span);
      this.userUnpairedDevicesTarget.appendChild(document.createElement("br"));

    });
  }

  refreshDevicesSelectValues(data) {
    this.devicesSelectTarget.innerHTML = "";

    const option = document.createElement("option");
    option.innerHTML = "----";
    this.devicesSelectTarget.appendChild(option);

    data.forEach((device) => {
      const option = document.createElement("option");
      option.value = device.id;
      option.innerHTML = device.name;
      this.devicesSelectTarget.appendChild(option);
    });
  }

  reloadAddress() {
    const controller = this;

    $.ajax({
      url: `/assembly_protocols/load_device_address.json`,
      type: 'get',
      data: `nom_device_id=${this.devicesSelectTarget.value}`,
      success: function (response) {
        controller.streetTarget.value = response.street
        controller.cityTarget.value = response.city
        controller.zipTarget.value = response.zip
        controller.flatNumberTarget.value = response.flat_number
        controller.clientContactTarget.value = response.client_contact
      }
    })
  }

  loadSerial() {
    const controller = this;

    $.ajax({
      url: `/assembly_protocols/load_serial.json`,
      type: 'get',
      data: `serial=${this.newDeviceNumberTarget.value}&user_id=${this.nomUserIdTarget.value}`,
      success: function (response) {
        console.log(response)
        const wmbusSerialAvailable = response.wmbus_serial && response.wmbus_serial !== ''
        controller.wmbusToggle(response, wmbusSerialAvailable)

        if (wmbusSerialAvailable) {
          controller.readingUnitTarget.value = '';
          controller.readingUnitGroupTarget.classList.add('hidden')
        }
        else {
          controller.tokenIdTarget.value = response.token_id
          if (response.not_available) {
            controller.newDeviceNumberNoticeTarget.classList.remove('hidden')
            controller.readingUnitGroupTarget.classList.remove('hidden')
          }
          else if (response.token_id && response.token_id !== '') {
            controller.kamstrupToggle(response)
          }
          else {
            controller.readingUnitGroupTarget.classList.remove('hidden')
            controller.newDeviceNumberNoticeTarget.classList.add('hidden')
          }
        }
      }
    })
  }

  wmbusToggle(response, wmbusSerialAvailable) {
    if (response.not_fls_wm_type && wmbusSerialAvailable) {
      this.manufacturerSelectTarget.classList.add('hidden')
      this.manufacturerTextTarget.classList.remove('hidden')
      this.manufacturerTextTarget.innerText = response.manufacturer
      this.meterTypeSelectTarget.classList.add('hidden')
      this.meterTypeTextTarget.classList.remove('hidden')
      this.meterTypeTextTarget.innerText = response.meter_type
      this.meterFlowSelectTarget.classList.add('hidden')
      this.meterFlowTextTarget.classList.remove('hidden')
      this.meterFlowTextTarget.innerText = response.meter_flow
      this.newDeviceNumberTarget.value = response.wmbus_serial

    }
    else {
      if (this.hasTargetManufacturerSelect) {
        this.manufacturerSelectTarget.classList.remove('hidden')
        this.manufacturerTextTarget.classList.add('hidden')
        this.meterTypeSelectTarget.classList.remove('hidden')
        this.meterTypeTextTarget.classList.add('hidden')
        this.meterFlowSelectTarget.classList.remove('hidden')
        this.meterFlowTextTarget.classList.add('hidden')
        }
    }
  }

  kamstrupToggle(response) {
    this.tokenIdTarget.value = response.token_id
    this.readingUnitTarget.value = '';
    this.readingUnitGroupTarget.classList.add('hidden')
    this.newDeviceNumberNoticeTarget.classList.add('hidden')

    this.manufacturerSelectTarget.value = response.manufacturer_id
    this.refreshSelectValues(this.meterTypeSelectTarget, response.meter_type_data)
    this.meterTypeSelectTarget.value = response.meter_type_id
    this.refreshSelectValues(this.meterFlowSelectTarget, response.meter_flow_data)
    this.meterFlowSelectTarget.value = response.meter_flow_id
  }

  loadTokenId() {
    const controller = this;

    $.ajax({
      url: `/assembly_protocols/load_token.json`,
      type: 'get',
      data: `reading_unit=${this.readingUnitTarget.value}`,
      success: function (response) {
        controller.tokenIdTarget.value = response.token_id
        if (response.token_id && response.token_id !== '') {
          controller.readingUnitNoticeTarget.classList.add('hidden')
        }
        else if (controller.readingUnitTarget.value !== '') {
          controller.readingUnitNoticeTarget.classList.remove('hidden')
        }
      }
    })
  }

  reloadMeterTypes() {
    const controller = this;

    $.ajax({
      // url: `/assembly_protocols/user_unpaired_devices.json`,
      url: `/assembly_protocols/load_meter_types.json`,
      type: 'get',
      data: `manufacturer_id=${this.manufacturerSelectTarget.value}`,
      success: function (response) {
        controller.refreshSelectValues(controller.meterTypeSelectTarget, response)
        controller.refreshSelectValues(controller.meterFlowSelectTarget, [])
      }
    })
  }

  reloadMeterFlows() {
    const controller = this;

    $.ajax({
      // url: `/assembly_protocols/user_unpaired_devices.json`,
      url: `/assembly_protocols/load_meter_flows.json`,
      type: 'get',
      data: `meter_type_id=${this.meterTypeSelectTarget.value}`,
      success: function (response) {
        controller.refreshSelectValues(controller.meterFlowSelectTarget, response)
      }
    })
  }

  refreshSelectValues(target, data) {
    target.innerHTML = "";

    const option = document.createElement("option");
    option.innerHTML = "----";
    target.appendChild(option);

    data.forEach((item) => {
      const option = document.createElement("option");
      option.value = item.id;
      option.innerHTML = item.name;
      target.appendChild(option);
    });
  }

  oldPhotoPreview() {
    let controller = this;
    let files = this.oldPhotoInputTarget.files

    const file = files[0];
    if (file) {
      controller.enablePhotoLoading(controller.oldPhotoLoadingTarget)

      const formData = new FormData(); // Create a new FormData object
      formData.append('old_device_photo', file);

      const picture_upload = $.ajax({
        url: '/assembly_protocols/' + this.protocolIdTarget.value + '/upload_photo',
        method: 'PATCH',
        contentType: false,
        processData: false,
        data: formData,
        // enctype: 'multipart/form-data',
        timeout: 60000,
        success: function(data, textStatus, jqXHR) {
          controller.disablePhotoLoading(controller.oldPhotoLoadingTarget);

          let reader = new FileReader()
          reader.onload = function() {
            controller.oldPhotoPreviewTarget.src = reader.result
            controller.oldPhotoPreviewTarget.classList.remove('hidden')
          }
          reader.readAsDataURL(file)
        },
        error: function(jqXHR, textStatus, errorThrown) {
          controller.disablePhotoLoading(controller.oldPhotoLoadingTarget);
        }
      });

      const abortButton = document.getElementById('old-photo-abort');
      abortButton.addEventListener('click', () => {
        picture_upload.abort()
      });
    }
  }

  newPhotoPreview() {
    let controller = this;
    let files = this.newPhotoInputTarget.files

    const file = files[0];
    if (file) {
      controller.enablePhotoLoading(controller.newPhotoLoadingTarget)

      const formData = new FormData(); // Create a new FormData object
      formData.append('new_device_photo', file);

      const picture_upload = $.ajax({
        url: '/assembly_protocols/' + this.protocolIdTarget.value + '/upload_photo',
        method: 'PATCH',
        contentType: false,
        processData: false,
        data: formData,
        // enctype: 'multipart/form-data',
        timeout: 60000,
        success: function(data, textStatus, jqXHR) {
          controller.disablePhotoLoading(controller.newPhotoLoadingTarget)

          let reader = new FileReader()
          reader.onload = function() {
            controller.newPhotoPreviewTarget.src = reader.result
            controller.newPhotoPreviewTarget.classList.remove('hidden')

          }
          reader.readAsDataURL(file)
        },
        error: function(jqXHR, textStatus, errorThrown) {
          controller.disablePhotoLoading(controller.newPhotoLoadingTarget)
        }
      });

      const abortButton = document.getElementById('new-photo-abort');
      abortButton.addEventListener('click', () => {
        picture_upload.abort()
      });
    }
  }

  enablePhotoLoading(target) {
    const buttons = document.querySelectorAll('.submit-protocol');
    buttons.forEach(button => {
      button.disabled = true;
    });

    target.classList.remove('hidden')
  }

  disablePhotoLoading(target) {
    const buttons = document.querySelectorAll('.submit-protocol');
    buttons.forEach(button => {
      button.disabled = false;
    });

    target.classList.add('hidden')
  }

  toggleRtxGroups() {
    if (this.wmbusCheckboxTarget.checked) {
      this.rtxGroupsTarget.classList.remove('hidden')
    }
    else {
      this.rtxGroupsTarget.classList.add('hidden')

    }
  }

  select2init() {
    const controller = this;
    window.addEventListener('turbolinks:load', () => {
      const select = $('.select2submit').select2({
        tags: true,
        tokenSeparators: [',', ' ']
      });
      select.on("change", function (e) {
        controller.reloadDevices();
      });
    })
  };

  initializeFileInputs() {
    var fileInputs = document.querySelectorAll('.file-input');

    fileInputs.forEach(function(fileInput) {
      var label = document.createElement('label');
      label.className = 'custom-file-label';
      label.textContent = 'Vybrat soubor';

      var container = document.createElement('div');
      container.className = 'file-input-container';
      fileInput.parentNode.insertBefore(container, fileInput);
      container.appendChild(fileInput);
      container.appendChild(label);

      label.addEventListener('click', function() {
        fileInput.click();
      });
    });
  };

  tookOverNotPresent() {
    this.tookOverNameTarget.value = this.tookOverNotPresentButtonTarget.innerText;
    this.tookOverEmailTarget.value = '';
    this.tookOverNotPresentButtonTarget.classList.add('hidden');
  }
}
