// app/javascript/controllers/checkbox_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkAll", "deviceCheckbox"];

  connect() {
  }

  toggleAll(event) {
    const category = event.target.getAttribute("category");
    const isChecked = event.target.checked;

    this.deviceCheckboxTargets.forEach((checkbox) => {
      if (checkbox.getAttribute("category") === category) {
        checkbox.checked = isChecked;
      }
    });
  }
}
